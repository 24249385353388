import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "orderInput", "submit"]

  connect() {
    this.draggedItem = null
    this.itemTargets.forEach(item => {
      item.addEventListener('dragstart', this.dragStart.bind(this))
      item.addEventListener('dragover', this.dragOver.bind(this))
      item.addEventListener('dragleave', this.dragLeave.bind(this))
      item.addEventListener('drop', this.drop.bind(this))
      item.addEventListener('dragend', this.dragEnd.bind(this))
    })
  }

  dragStart(event) {
    this.draggedItem = event.target
    event.target.classList.add('opacity-50')
  }

  dragOver(event) {
    event.preventDefault()
    const item = event.target.closest('[data-drag-drop-target="item"]')
    if (!item) return

    if (this.draggedItem !== item) {
      item.classList.add('border-2', 'border-grey-400')
    }
  }

  dragLeave(event) {
    const item = event.target.closest('[data-drag-drop-target="item"]')
    if (!item) return

    item.classList.remove('border-2', 'border-grey-400')
  }

  drop(event) {
    event.preventDefault()
    const dropTarget = event.target.closest('[data-drag-drop-target="item"]')
    if (!dropTarget || this.draggedItem === dropTarget) return

    const form = this.draggedItem.querySelector('form')
    const orderInput = form.querySelector('[data-drag-drop-target="orderInput"]')
    const submitButton = form.querySelector('[data-drag-drop-target="submit"]')

    orderInput.value = dropTarget.dataset.order
    submitButton.click();
  }

  dragEnd(event) {
    event.target.classList.remove('opacity-50')
    this.itemTargets.forEach(item => {
      item.classList.remove('border-2', 'border-blue-500')
    })
  }
}