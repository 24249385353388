import { Controller } from "@hotwired/stimulus"
import { throttle } from "lodash"

export default class extends Controller {
  static targets = ['input', 'menu', 'typeField', 'form', 'results', 'loader', 'dropdownTitle']

  connect() {
    this.timeout = null
    this.currentPage = 1
    this.loading = false
    this.hasMorePages = true

    // Throttle scroll handler to prevent too many calls
    this.handleScroll = throttle(this._handleScroll.bind(this), 300)

    // Add scroll listener to results container
    this.resultsTarget.addEventListener('scroll', this.handleScroll)

    this.updateDropdownTitle(this.menuTargets[0].innerHTML);
  }

  disconnect() {
    this.resultsTarget.removeEventListener('scroll', this.handleScroll)
  }

  async _handleScroll() {
    if (this.loading || !this.hasMorePages) return

    const container = this.resultsTarget
    const scrollPosition = container.scrollTop + container.clientHeight
    const scrollThreshold = container.scrollHeight - 100 // Load more when 100px from bottom

    if (scrollPosition >= scrollThreshold) {
      await this.loadMoreResults()
    }
  }

  async loadMoreResults() {
    console.log('Loading more results...')
    this.loading = true
    console.log('show loader')
    this.loaderTarget.classList.remove('hidden')

    this.currentPage += 1
    this.element.querySelector('[name="page"]').value = this.currentPage

    try {
      await this.formTarget.requestSubmit()
      console.log(this.element.querySelector('.no-more-data'))

      const noMoreData = this.element.querySelector('.no-more-data')
      console.log(noMoreData)
      if (noMoreData !== null && noMoreData !== undefined) {
        console.log('No more data')
        this.hasMorePages = false
      } else {
        console.log('More data')
      }
    } catch (error) {
      console.error('Error loading more results:', error)
    } finally {
      this.loading = false
      // console.log('hide loader')
      // this.loaderTarget.classList.add('hidden')
    }
  }

  perform() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.resetPagination()
      this.formTarget.requestSubmit()
    }, 300)
  }

  select() {
    this.resetPagination()
    this.formTarget.requestSubmit()
  }

  changeType(event) {
    event.preventDefault()
    const selectedType = event.currentTarget.dataset.id

    this.typeFieldTarget.value = selectedType

    this.menuTargets.forEach((menu) => {
      if (selectedType === menu.dataset.id) {
        menu.classList.add('bg-secondary-regular')
        menu.classList.remove('bg-secondary-light')
        this.updateDropdownTitle(menu.innerHTML);
      } else {
        menu.classList.remove('bg-secondary-regular')
        menu.classList.add('bg-secondary-light')
      }
    })

    this.resetPagination()
    this.formTarget.requestSubmit()
  }

  resetPagination() {
    this.currentPage = 1
    this.hasMorePages = true
    this.element.querySelector('[name="page"]').value = 1
  }

  updateDropdownTitle(newTitle) {
    this.dropdownTitleTarget.innerHTML = newTitle;
  }
}