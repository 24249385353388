import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdown", "selectedTitle", "selectedExplanation", "input"]
  static values = { selected: String }

  connect() {
    this.closeDropdown = this.closeDropdown.bind(this)
    document.addEventListener("click", this.closeDropdown)
    console.log('selectedValue');
    console.log(this.selectedValue);
    if (!this.inputTarget.value) {
      this.inputTarget.value = this.selectedValue
    }
  }

  disconnect() {
    document.removeEventListener("click", this.closeDropdown)
  }

  toggleDropdown(event) {
    event.stopPropagation()
    this.dropdownTarget.classList.toggle("hidden")
  }

  selectOption(event) {
    const selectedValue = event.currentTarget.dataset.value
    const selectedTitle = event.currentTarget.querySelector(".title").textContent
    const selectedExplanation = event.currentTarget.querySelector("p").textContent

    this.selectedTitleTarget.textContent = selectedTitle
    this.selectedExplanationTarget.textContent = selectedExplanation
    this.inputTarget.value = selectedValue

    this.closeDropdown()
  }

  closeDropdown(event) {
    if (!event || !this.element.contains(event.target)) {
      this.dropdownTarget.classList.add("hidden")
    }
  }
}