import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["items", "loading", "noMoreItems", "loadMore"]
  static values = { page: Number }

  initialize() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.load()
        }
      })
    })

    this.intersectionObserver.observe(this.loadingTarget)
  }

  disconnect() {
    this.intersectionObserver.disconnect()
  }

  load() {
    if (this.loading) return

    this.loading = true
    this.loadingTarget.classList.remove("hidden")
    this.loadMoreTarget.click()
  }

  loadMoreSuccess() {
    this.pageValue++
    this.loading = false
    this.loadingTarget.classList.add("hidden")

    const newItems = this.element.querySelectorAll(`[data-page="${this.pageValue}"]`)
    if (newItems.length === 0) {
      this.noMoreItemsTarget.classList.remove("hidden")
      this.loadingTarget.classList.add("hidden")
      this.intersectionObserver.disconnect()
    }
  }
}